<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['iossNumbers_iossNumber',])"></div>
      <CardLeftBlock
          :name="$t('iossNumbers_iossNumber.localization_value.value')"
          :value="'#' + IOSSNumber.data.id"
          :backgroundImage="'customs-info'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.mainSettingsIOSSNumbers" class="order-create__head-back">
                  <LinkBack
                      :value="$t('iossNumbers_BackToList.localization_value.value')"
                      @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['iossNumbers_iossNumber',])"></div>
              {{$t('iossNumbers_iossNumber.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['iossNumbers_category'])"></div>
                  {{ $t('iossNumbers_category.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">{{ IOSSNumber.data.category }}</div>
                </div>
              </div>
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['iossNumbers_country'])"></div>
                  {{ $t('iossNumbers_country.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">{{ getCountryName }}</div>
                </div>
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['iossNumbers_marketplace'])"></div>
                  {{ $t('iossNumbers_marketplace.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">{{ IOSSNumber.data.marketplace }}</div>
                </div>
              </div>
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['iossNumbers_number'])"></div>
                  {{ $t('iossNumbers_number.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">{{ IOSSNumber.data.number }}</div>
                </div>
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--75 custom-col--md-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['iossNumbers_comment'])"></div>
                  {{ $t('iossNumbers_comment.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">{{ IOSSNumber.data.comment }}</div>
                </div>
              </div>
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import {IOSSNumbers} from "../models/IOSSNumbers";
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";

  export default {
    name: "IOSSNumbersShow",

    components:{
      LinkBack,
      CardRightBlock,
      CardLeftBlock

    },

    data() {
      return {
        IOSSNumber: new IOSSNumbers(),
      }
    },

    computed: {
      getCountryName() {
        return this.IOSSNumber.getCountry()?.currentTranslate?.name || ''
      }
    },

    mounted() {
      this.initIOSSNumbersShow()
    },

    methods: {
      initIOSSNumbersShow() {
          this.IOSSNumber.setId(this.$route.params.id)

          this.$store.dispatch('getIOSSNumbers', this.IOSSNumber.getId()).then((response) => {
            let item = this.getRespData(response)
            this.IOSSNumber.setItem(item, this)
          }).catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
